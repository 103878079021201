@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #d8d8e8;
}

::-webkit-scrollbar-thumb {
  background: #7e7e94;
}

html {
  overflow-x: hidden;
}

[role="tab"] > div {
  width: 100%;
}

.swiper {
  overflow: visible !important;
  max-width: 100%;
  margin: unset;

  .swiper-slide {
    width: auto !important;
    height: auto !important;
  }
}

.card-tabs {
  > div {
    overflow: visible;
  }
}

.no-scroll #root > div {
  height: 100vh;
  overflow: hidden;
}
